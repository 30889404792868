<template>
    <div class="container">
        <section class="orders-header">
            <h1 class="titlePage titlePage_textLeft">{{getUserName}}</h1>

            <breadcrumbs v-if="!isEdit"></breadcrumbs>

        </section>

        <section>
            <h2 class="titlePage titlePage_subtitle titlePage_mb">SELECT FRAME COLOUR</h2>
            <div class="row">
                <div class="col-12 col-md-6">
                    <form action="" class="form" v-on:submit.prevent>
                        <label class="form__label">
                            <input type="text" class="form__input"
                                   placeholder="Type to Search"
                                   name="search color"
                                   v-model="color"
                            >
                        </label>
                        <button type="button" class="btn_link">
                            <svg-icon name="search"/>
                        </button>
                    </form>
                </div>
            </div>

            <div class="divider">
                <p class="divider__text text_green">OR SELECT BELOW</p>
            </div>
        </section>
        <section class="item-types">
            <div class="row"
                 v-for="(item, key, index) in colors"
            >
                <div class="col-12">
                    <h2 class="titlePage_subtitle titlePage">{{key}}</h2>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col-6"
                     v-for="color in item.colours"
                     :key="color.id"
                     @click="selectColors(color, item, key)"
                >
                    <div class="box">
                        <div class="colours colour_box">
                            <v-lazy-image-plugin :src="color.image" v-if="color.image"/>
                            <p v-else-if="color.hexcode">
                                <svg-icon name="palette" class="large_icon box__img" :style="{color: color.hexcode}"/>
                            </p>
                            <p v-if="!color.image && !color.hexcode">
                                <svg-icon name="palette" class="large_icon box__img"/>
                            </p>
                        </div>
                        <p class="text_default">{{color.colour_name}}</p>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal modal_wrap" v-if="warning">
            <div class="modal_popup">
                <p class="text text_white">Chosen colour requires additional price. Please, click "I Accept" button if
                    you want to continue.</p>
                <div class="justify-content-between">
                    <button class="btn btn_transparent" @click="warning = !warning"><svg-icon name="solid/step-backward" /> Back</button>
                    <button class="btn btn_transparent" @click="accept">I Accept</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../../router";
    import breadcrumbs from "./../parts/breadcrumbs";
    import {searchColors} from "../../helpers/search";

    import VLazyImagePlugin from "v-lazy-image";


    export default {
        name: "SelectColors",
        data() {
            return {
                color: '',
                warning: false,
                colorItem: {},
            }
        },
        components: {breadcrumbs, VLazyImagePlugin},
        methods: {
            selectColors(target, parent, group) {
                localStorage.setItem('colorGroup', group);
                this.colorItem = target;
                this.$store.commit('orderPosition/setFrameColour', target);

                if (parent.is_show_price_warning) {
                    this.warning = !this.warning;
                }
            },

            accept() {
                this.warning = !this.warning;
                this.$store.commit('orderPosition/setFrameColour', this.colorItem);
            },
        },
        watch: {
            color(n) {
                this.$store.commit('colors/filtered', searchColors(n, this.allColors));
            }
        },
        computed: {
            getUserName() {
                return localStorage.getItem('nameUser');
            },
            user() {
                return this.$store.getters['colors/user'];
            },
            allColors() {
                return this.$store.getters['colors/colors'];
            },
            colors() {
                let colours =  this.$store.getters['colors/filtrated']
                let colourList = Object.values(colours).flatMap(i => i.colours)
                let locations =  this.$store.getters['orderPreview/items'].locations
                let orderCoulourIds = Object.values(locations).flatMap(i => (i.items.standard || []).flatMap(k => k.colour_id))
                let defaultColourList = colourList.filter(c => orderCoulourIds.includes(c.id))
                let systemDefaults = colourList.filter(c => {return c.is_system_default && !defaultColourList.includes(c)})
                let defaultColour = { default:{ colours: defaultColourList.concat(systemDefaults), is_show_price_warning: false}}
                return { ...defaultColour, ...colours }
            },
            isEdit() {
                return this.$store.getters['orderPosition/isCompleted']
            },
// >>>>>>> 1195684220473532-add-dimension
        },

        mounted() {
            this.$store.dispatch('colors/getColors');
        },

        created() {
            this.$store.commit('options/cleanOptions');
        },

    }
</script>

<style scoped lang="scss">

    @import "../../../src/assets/scss/utils/vars";

    .box {
        margin-bottom: 1rem;
        &__img {
            position: static;
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
            width: 100%;
            max-width: 160px;
            height: 100px;
        }
        p {
            text-align: center;
        }
    }

    .form {
        position: relative;
        .btn_link {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            svg {
                color: $green;
            }
        }

        &__input {
            padding-right: 40px;
            border: 2px solid #0b3553;
        }

    }

    .justify-content-between {
        display: flex;
    }

</style>
